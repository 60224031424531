.headerContainer {
  text-align: center;
}

.name {
  font-size: 22px;
}

.linkButton {
  width: 35px;
  height: 35px;
}

.copyButton {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 18px;  
}

.mediaContainer {
  background-color: #fafafa;
  height: 60px;
  max-width: 100%;
  width: 450px;
  border-radius: 12px;
  display: inline-flex;
  gap: 30%;
  align-items: center;
  align-self: center;
  cursor: pointer;
}

a {
 text-decoration: none;
 color: #000000;
}

.mediaContainer:hover{
  background-color: #f7f2f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mediaName {
  font-size: 18px;
  margin-left: 10px; 
}

.twitterMediaName {
  font-size: 18px;
}

.linkLogo {
  width: 30px;
  height: 30px;
}

.mediaLogo {
  width: 40px;
  height: 40px;
  margin-left: 4px;
}

.xLogo {
  width: 35px;
  height: 35px;
  margin-left: 4px;
  margin-right: 14px; 
}

.emailLogo {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-bottom: -8px;
}

.mediaContainer p {
  margin: 0;
}

.profilePic {
  height: 28vmin;
  pointer-events: none;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.App-header {
  margin-top: 5%;
} 

.copyright {
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  bottom: 0; 
  right: 10px;
}

.copyrightCollectionView {
  position: absolute;
  font-weight: bold;
  font-size: 12px;
  right: 10px;
  padding-bottom: 10px;
}

.collectionContainer {
  margin-bottom: 30px;
  cursor: pointer;
}

.collectionItemContainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.collectionImg {
  width: 400px;
  height: 600px;
}

.spanText {
  font-weight: bold;
}

.collectionLink {
  text-decoration: underline;
  display: flex;
  color: #1e30f7;
  margin-top: -30px; 
}

.inspoHeader {
  text-decoration: underline;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 30px;
  cursor: pointer;
}

.quote {
  max-width: 100%;
  width: 380px;
  margin-bottom: 20px;
  background-color: #f7f2f2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.scrollupButton {
  position: fixed;
  bottom: 25px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
 }

 .eyesImg {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: -8px;
 }

 .countContainer {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  display: none;
 }

@media (max-width: 767px) {
.mediaContainer {
 margin-left: 2px;
 margin-right: 2px;
 width: 380px;
 } 

 .linkButton {
  width: 28px;
  height: 28px;
 }

 .scrollupButton {
  display: none;
 }
}